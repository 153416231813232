import { render, staticRenderFns } from "./Ysfpay.vue?vue&type=template&id=5c798981&scoped=true"
import script from "./Ysfpay.vue?vue&type=script&lang=js"
export * from "./Ysfpay.vue?vue&type=script&lang=js"
import style0 from "./Ysfpay.vue?vue&type=style&index=0&id=5c798981&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c798981",
  null
  
)

export default component.exports